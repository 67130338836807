<template>
	<div>
		<!-- disabled-per-page -->
		<base-data-table
			:title="$t('banks')"
			:headers="headers"
			:provider="$adminBanks"
			:actions="actions"
			:fetch-function="fetchBanks"
			translate
			@view="onViewBank"
			@update="onUpdateBank"
			@delete="onDeleteBank"
			@click:add="onInitBank"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					$adminBanks.perPage * ($adminBanks.currentPage - 1) + index + 1
				}}
			</template>
			<template v-slot:[`item.bankName`]="{ item }">
				<span class="tw-whitespace-nowrap">{{ item.bankName }}</span>
			</template>
			<template v-slot:[`item.updatedAt`]="{ item }">
				<span class="tw-whitespace-nowrap">{{
					$m(item.updatedAt).calendar()
				}}</span>
			</template>
		</base-data-table>

		<v-dialog
			v-model="bankForm.dialog"
			persistent
			max-width="800"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<BankForm
				:errors.sync="error"
				:bank="bankForm.data"
				:on-view.sync="onView"
				:loading="bankForm.loading"
				:updateFunc="handleUpdateBank"
				:createFunc="handleCreateBank"
				@close="onResetBankForm"
			/>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			hide-id
			:title="$t('bank')"
			:id.sync="onDeleteId"
			:delete-function="deleteBank"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BankForm from '../../components/forms/BankForm.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

export default {
	name: 'Banks',
	components: {
		BankForm,
		DialogDeleteConfirmation,
	},
	data() {
		return {
			error: {},
			onView: false,
			onDeleteId: null,
			confirmDialog: false,
			bankForm: {
				data: null,
				dialog: false,
				loading: false,
			},
			headers: [
				{
					align: 'start',
					value: 'serial',
					text: 'headers.ns',
				},
				{
					text: 'ID',
					align: 'start',
					value: 'id',
				},
				{
					align: 'start',
					value: 'bankName',
					text: 'headers.name',
				},
				{
					value: 'details',
					text: 'headers.details',
				},
				{
					value: 'updatedAt',
					text: 'headers.updatedAt',
				},
				{ text: '', value: 'actions' },
			],
		}
	},
	computed: {
		...mapGetters('bank', ['$adminBanks']),
		...mapGetters('auth', ['$currentUser']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				{
					text: this.$t('actions.editDetails'),
					event: 'update',
					icon: 'mdi-pencil-outline',
				},
				{
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
				},
			]
		},
	},
	methods: {
		...mapActions('bank', [
			'fetchBanks',
			'deleteBank',
			'createBank',
			'updateBank',
		]),
		onResetBankForm() {
			this.bankForm.data = {}
			this.bankForm.dialog = false
			this.onView = false
			this.error = {}
		},
		onInitBank() {
			this.bankForm.dialog = true
		},
		onUpdateBank(bank) {
			this.bankForm.data = bank
			this.bankForm.dialog = true
		},
		onViewBank(bank) {
			this.bankForm.data = bank
			this.bankForm.dialog = true
			this.onView = true
		},
		onDeleteBank(bank) {
			this.confirmDialog = true
			this.onDeleteId = bank.id
		},
		async handleCreateBank(bank) {
			this.bankForm.loading = true
			let [err, res] = await this.createBank(bank)
			this.bankForm.loading = false

			if (err) {
				this.error = err.errors
				this.$toast.error(this.$t('error-creating', [this.$t('bank')]))
				return
			}

			this.$toast.success(this.$t('success-create', [this.$t('bank')]))
			this.onResetBankForm()
			return [err, res]
		},
		async handleUpdateBank(bank) {
			this.bankForm.loading = true
			let [err, res] = await this.updateBank(bank)
			this.bankForm.loading = false

			if (err) {
				this.error = err.errors
				this.$toast.error(this.$t('error-updating', [this.$t('bank')]))
				return
			}

			this.$toast.success(this.$t('success-update', [this.$t('bank')]))
			this.onResetBankForm()
			return [err, res]
		},
	},
}
</script>

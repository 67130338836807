<template>
	<v-form
		:readonly="onView"
		ref="form"
		lazy-validation
		@submit.prevent="validateBank"
	>
		<v-card class="tw-overflow-x-hidden tw-overflow-y-auto">
			<v-card-text class="tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6">
				<div class="tw-flex tw-justify-between tw-items-start">
					<h2
						class="tw-text-22 tw-font-semibold tw-text-black tw-mb-6 lg:tw-mb-12"
					>
						{{
							onView
								? $t('viewBankInfos')
								: isUpdate
								? $t('editBank')
								: $t('addBank')
						}}
					</h2>

					<div v-if="onView" class="tw-flex tw-space-s-3 tw-ms-auto">
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							tile
							color="#F2CA51"
							outlined
							@click="onUpdateFromView"
							icon
						>
							<v-icon color="tw-text-F2 ">mdi mdi-pencil</v-icon>
						</v-btn>
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							fab
							@click="onResetBankForm"
							color="#DF9999"
							dark
						>
							<v-icon color="tw-text-white">mdi mdi-close</v-icon>
						</v-btn>
					</div>
					<div v-if="isUpdate" class="tw-flex tw-space-s-3">
						<v-btn
							:loading="loading"
							fab
							dark
							depressed
							width="50"
							height="50"
							color="#F2CA51"
							class="tw-rounded-md"
							@click="validateBank"
						>
							<v-icon color="tw-text-white">mdi mdi-check</v-icon>
						</v-btn>
						<v-btn
							width="50"
							height="50"
							class="tw-rounded-md"
							depressed
							fab
							@click="onResetBankForm"
							color="#DF9999"
							dark
						>
							<v-icon color="tw-text-white">mdi mdi-close</v-icon>
						</v-btn>
					</div>
				</div>

				<v-row>
					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('bankName') }}
						</div>
						<v-text-field
							v-model="mBank.bankName"
							:rules="[rules.required($t('bankName'))]"
							:placeholder="$t('enterBankName')"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="mErrors.bankName || []"
							flat
							dense
							outlined
							name="bankName"
							class="user-input"
							hide-details="auto"
							background-color="#FBFBFB"
						/>
					</v-col>
					<v-col cols="12">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('bankDetails') }}
						</div>
						<v-textarea
							v-model="mBank.details"
							rows="5"
							:placeholder="$t('enterBankDetails')"
							:rules="[
								rules.required($t('bankDetails')),
								rules.max(1200),
							]"
							:error-messages="mErrors.details || []"
							flat
							dense
							outlined
							class="user-input"
							name="bankDetails"
							hide-details="auto"
							background-color="#FBFBFB"
						/>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions
				v-if="isCreate"
				class="tw-flex tw-px-4 lg:tw-px-14 lg:tw-pb-9 tw-pb-6"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetBankForm"
				>
					<span class="tw-text-lg tw-font-medium tw-capitalize tw-text-72">
						{{ $t('actions.cancel') }}
					</span>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					color="primary"
					:loading="loading"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
					>
						{{ $t('actions.submit') }}
					</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'
import { omitEmpties } from 'vuelpers'

export default {
	name: 'BankForm',
	mixins: [
		createFormMixin({
			rules: ['required', 'max'],
		}),
	],
	props: {
		bank: Object,
		errors: {
			type: Object,
			default: () => ({}),
		},
		onView: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		createFunc: Function,
		updateFunc: Function,
	},
	watch: {
		bank: {
			deep: true,
			immediate: true,
			handler(v) {
				this.mBank = this.initialBank(v || {})
			},
		},
	},
	data() {
		return {
			mBank: {},
			wasViewing: false,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentBank']),
		mErrors: {
			get() {
				return this.errors
			},
			set(v) {
				this.$emit('update:errors', v)
			},
		},
		isUpdate() {
			return !!this.mBank.id && !this.onView
		},
		isCreate() {
			return !this.mBank.id || (!this.isUpdate && !this.onView)
		},
	},
	methods: {
		...mapActions('users', ['getBuyers', 'getSuppliers']),
		initialBank(v = {}) {
			return {
				id: v?.id,
				bankName: v?.bankName,
				details: v?.details,
			}
		},
		onResetBankForm() {
			if (this.wasViewing) {
				this.wasViewing = false
				this.$emit('update:on-view', true)
				return
			}

			this.$refs.form.reset()
			this.$emit('close')
		},
		async validateBank() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			const func = this.isUpdate ? this.updateFunc : this.createFunc
			const [err] = await func(
				omitEmpties({
					...this.mBank,
					bankId: this?.mBank?.id,
				})
			)

			if (err) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.onResetBankForm()
		},
		onUpdateFromView() {
			this.wasViewing = true
			this.$emit('update:on-view', false)
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
